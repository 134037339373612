<template>
  <div class="main-content-container">
    <div class="section-header1">
      <i class="fa fa-building"></i> <!-- Business icon -->
      <span class="section-title company-details">{{ jsonData? jsonData.companyInfoHeader : 'Company Details' }}</span>
    </div>
    <!-- Company information section -->
    <div class="company-info">
      <!-- Use us as your Registered Agent section -->
      <div class="registered-agent-section">
        <div class="section-header2">
          <i class="fa fa-check-circle"></i>
          <span class="section-title">{{ jsonData? jsonData.registeredAgentHeader : 'Use us as your Registered Agent:' }}</span>
        </div>
        <div class="checkbox-container">
          <input type="checkbox" id="registeredAgent" v-model="useRegisteredAgent" />
          <label for="registeredAgent">{{ jsonData? jsonData.registeredAgentCheckbox : 'Yes, use as registered agent' }}</label>
        </div>
      </div>

      <!-- Intended Activities section -->
      <div class="intended-activities-section">
        <div class="section-header">
          <i class="fa fa-tasks"></i>
          <span class="section-title">{{ jsonData ? jsonData.intendedActivitiesHeader : 'Intended Activities:' }}</span>
        </div>
        <div class="form-group">
          <textarea id="intendedActivities" v-model="intendedActivities"></textarea>
          <span v-if="intendedActivitiesError" class="error-message">{{ jsonData? jsonData.intendedActivitiesError : 'Please enter intended activities.' }}</span>
        </div>
      </div>
    </div>

    <!-- Next button -->
    <div class="next-button">
      <button class="next-btn" :disabled="!isFormValid" @click="handleNextClick">
        {{ jsonData?.nextButton || 'Next' }} <i class="fa fa-arrow-right"></i>
      </button>
    </div>

    <!-- Existing upload view and components -->
    <div class="upload-view">
      <!-- Your existing upload items and components -->
    </div>

    <!-- Modal and other components -->
    <!-- Assuming these are necessary for your application -->
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export default {
  name: "BaseContent",

  setup() {
    const jsonData = ref(null); // This will hold your language JSON data

    const store = useStore();
    const useRegisteredAgent = ref(localStorage.getItem('useRegisteredAgent') === 'true');
    const intendedActivities = ref(localStorage.getItem('intendedActivities') || '');

    const intendedActivitiesError = computed(() => {
      return intendedActivities.value.trim() === '';
    });

    const isFormValid = computed(() => {
      return !intendedActivitiesError.value; // Add more validation checks if needed
    });

    const goToStep = (step) => {
      store.commit('setStep', step);
      store.commit('updateCurrentComponent', 'testingphase');
    };

    const handleNextClick = () => {
      if (isFormValid.value) {
        localStorage.setItem('useRegisteredAgent', useRegisteredAgent.value);
        localStorage.setItem('intendedActivities', intendedActivities.value);

        console.log('useRegisteredAgent saved to localStorage:', useRegisteredAgent.value);
        console.log('intendedActivities saved to localStorage:', intendedActivities.value);

        goToStep(3); // Navigate to step 3
      }
    };

    // Watch for changes to useRegisteredAgent and intendedActivities to save them to localStorage
    watch(useRegisteredAgent, (newValue) => {
      localStorage.setItem('useRegisteredAgent', newValue);
      console.log('useRegisteredAgent updated:', newValue);
    });
    const loadLanguageFile = async (lang) => {
  if (lang === 'vn') {
    jsonData.value = await import('../locales/vi.json'); // Load Vietnamese JSON
    console.log('json data',jsonData)
  } else {
    jsonData.value = await import('../locales/en.json'); // Default to English
  }
};
const route = useRoute(); // Use vue-router to get the current route

    // Watch for changes in the 'lang' route parameter
watch(
  () => route.params.lang,
  (newLang) => {
    loadLanguageFile(newLang || 'en'); // Load the correct language file, default to 'en'
  },
  { immediate: true } // Run immediately on component mount
);

    watch(intendedActivities, (newValue) => {
      localStorage.setItem('intendedActivities', newValue);
      console.log('intendedActivities updated:', newValue);
    });

    return {
      useRegisteredAgent,
      intendedActivities,
      intendedActivitiesError,
      isFormValid,
      handleNextClick,
      jsonData
    };
  },
};
</script>

<style scoped>
/* Your existing scoped styles */
.main-content-container {
  /* Example styles for layout */
  max-width: 800px;
  margin: 25px;
  padding: 12px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Updated font family */
  background-color: #f0f0f0; /* Light background color */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow for depth */
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #007bff; /* Updated text color for the title */
}

.company-info {
  margin-bottom: 20px;
}

.section-header2 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 25px;
}

.section-header1 {
  align-items: center;
  margin-bottom: 10px;
  margin-top: 25px;
}

.section-header {
  align-items: center;
  margin-bottom: 10px;
  margin-top: 25px;
}

.section-header {
  font-size: 24px;
  margin-right: 10px;
  color: #ab3c50; 
}

.section-header i {
  font-size: 15px;
  margin-right: 10px;
  color: #ab3c50; 
  padding: 20px;
}

.section-header1 i {
  font-size: 24px;
  margin-right: 10px;
  color: #ab3c50; 
  padding: 10px;
}

.section-header2 i {
  font-size: 15px;
  margin-right: 10px;
  color: #ab3c50; 
  padding: 10px;
}

.section-title {
  font-size: 18px;
  font-weight: bold;
  color: #333; /* Color for section titles */
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-left: 25px;
}

.checkbox-container input[type="checkbox"] {
  margin-right: 10px;
}

textarea {
  width: 100%;
  min-height: 100px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5;
}

.registered-agent-section {
  background-color: #f9f9f9; /* Background color for registered agent section */
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.intended-activities-section {
  background-color: #f9f9f9; /* Background color for intended activities section */
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.next-button {
  text-align: right;
  margin-top: 20px;
}

.next-btn {
  padding: 12px 24px;
  background-color: #ee2b4d; /* Button background color */
  color: #fff; /* Button text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.next-btn:hover {
  background-color: #ff0044; /* Button hover background color */
}

.fa {
  margin-left: 8px;
}

.company-details {
  margin-bottom: 54px;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
</style>
