<template>
  <main class="app-main-content">
    <div v-if="loading">
      <ProgressSpinner class="spinner" />
    </div>
    <div v-if="loading" class="columns-container-spinner">
      <p class="container-spinner"></p>
    </div>
    <div v-else class="columns-container">
      <div class="steps" :style="{ width: '25%', maxWidth: '600px' }">
        <div class="column">
          <div class="row header">
            <SvgIcon label="1" :active="currentStep" :stepNumber="1">1</SvgIcon>
          </div>
          <div class="actions">
            <div class="row header-label">{{ jsonData ? jsonData.stepLabel : 'STEP' }}</div>
            <div class="row content">{{ jsonData ? jsonData.businessName : 'businessName' }}</div>
          </div>
        </div>

        <div class="column">
          <div class="row header"></div>
          <div class="actions">
            <div class="row header">
              <svg xmlns="http://www.w3.org/2000/svg" width="4" height="83" viewBox="0 0 4 83" fill="none">
                <path d="M2 2L2 81" stroke="#858EA1" stroke-width="3" stroke-linecap="round" />
              </svg>
            </div>
            <div class="row content"></div>
          </div>
        </div>

        <div class="column">
          <div class="row header">
            <SvgIcon label="2" :active="currentStep" :stepNumber="2">2</SvgIcon>
          </div>
          <div class="actions">
            <div class="row header-label">{{ jsonData ? jsonData.stepLabel : 'STEP' }}</div>
            <div class="row content">{{ jsonData ? jsonData.companyInfo : 'Company Info' }}</div>
          </div>
        </div>

        <div class="column">
          <div class="row header"></div>
          <div class="actions">
            <div class="row header-label">
              <svg xmlns="http://www.w3.org/2000/svg" width="4" height="83" viewBox="0 0 4 83" fill="none">
                <path d="M2 2L2 81" stroke="#858EA1" stroke-width="3" stroke-linecap="round" />
              </svg>
            </div>
            <div class="row content"></div>
          </div>
        </div>

        <div class="column">
          <div class="row header">
            <SvgIcon label="3" :active="currentStep" :stepNumber="3">3</SvgIcon>
          </div>
          <div class="actions">
            <div class="row header-label">{{ jsonData ? jsonData.stepLabel : 'STEP' }}</div>
            <div class="row content">{{ jsonData ? jsonData.businessOwner : 'Business owner' }}</div>
          </div>
        </div>

        <div class="column">
          <div class="row header"></div>
          <div class="actions">
            <div class="row header-label">
              <svg xmlns="http://www.w3.org/2000/svg" width="4" height="83" viewBox="0 0 4 83" fill="none">
                <path d="M2 2L2 81" stroke="#858EA1" stroke-width="3" stroke-linecap="round" />
              </svg>
            </div>
            <div class="row content"></div>
          </div>
        </div>

        <div class="column">
          <div class="row header">
            <SvgIcon label="4" :active="currentStep" :stepNumber="4">4</SvgIcon>
          </div>
          <div class="actions">
            <div class="row header-label">{{ jsonData ? jsonData.stepLabel : 'STEP' }}</div>
            <div class="row content">{{ jsonData ? jsonData.payment : 'Payment' }}{{ $t('payment') }}</div>
          </div>
        </div>
      </div>
      <component
        :is="currentComponent"
        :recommendedModels="recommendedModels"
        v-if="currentComponent !== 'UploadFile' && currentComponent !== 'testingphase' && currentComponent !== 'deploy'"
      />
      <UploadFile v-else-if="currentComponent === 'UploadFile'" />
      <TestingPhase v-else-if="currentComponent === 'testingphase'" />
      <DeployContent v-else-if="currentComponent === 'deploy'" />
    </div>
  </main>
</template>

<script>
import { ref, computed,watch} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import '@fortawesome/fontawesome-free/css/all.css';
import BaseContent from '../maincontent/BaseContent.vue'; 
import ModelToUseContent from '../maincontent/ModelToUseContent.vue';
import UploadFile from '../maincontent/UploadFile.vue';
import TestingPhase from '../maincontent/testModel.vue';
import DeployContent from '../maincontent/DeployContent.vue'; 
import ProgressSpinner from 'primevue/progressspinner';
import SvgIcon from '../tools/SvgIcons.vue';
import BaseInput from './BaseInput';
import { recommendAiModel } from "../services/modelrecommended.service.js";




export default {
  name: 'AppMainContent',
  props: {
    label: String,
    active: Boolean
  },
  setup() {
    const jsonData = ref(null); // This will hold your language JSON data

    const store = useStore();
    const currentStep = computed(() => store.state.currentStep);
    const currentComponent = computed(() => store.state.currentComponent);
    const requirement = ref('');
    const loading = ref(false);
    const isValid = ref(true);

    const sendRequest = async() => {
      if (!requirement.value.trim()) {
        isValid.value = false;
        return;
      }
      loading.value = true;
      isValid.value = true;

      try {
        const response = await recommendAiModel(requirement.value);
        if (Array.isArray(response)) {
          store.commit('updateRecommendedModels', response);
        } else {
          console.error('Response is not an array:', response);
        }
        store.commit('updateCurrentComponent', 'ModelToUseContent');
        store.commit('updateStepsWidth', '20%');
      } catch (error) {
        console.error('Error:', error);
      } finally {
        loading.value = false;
      }
    };
    const loadLanguageFile = async (lang) => {
  if (lang === 'vn') {
    jsonData.value = await import('../locales/vi.json'); // Load Vietnamese JSON
    console.log('json data',jsonData)
  } else {
    jsonData.value = await import('../locales/en.json'); // Default to English
  }
};
const route = useRoute(); // Use vue-router to get the current route

// Watch for changes in the 'lang' route parameter
watch(

  () => route.params.lang,
  (newLang) => {
    console.log('new lang',newLang)
    loadLanguageFile(newLang || 'en'); // Load the correct language file, default to 'en'
  },
  { immediate: true } // Run immediately on component mount
);


    return {
      requirement,
      loading,
      currentStep,
      sendRequest,
      currentComponent,
      isValid,
      jsonData
    };
  },
  components: {
    BaseContent,
    BaseInput,
    ModelToUseContent,
    SvgIcon,
    UploadFile,
    TestingPhase,
    ProgressSpinner,
    DeployContent,
    
  }
};
</script>

<style scoped>
.input-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: 60px;
}

.full-width-input {
  width: 100%;
  padding: 10px 50px 10px 20px;
  border-radius: 32.5px;
  border: 1px solid #d3cece;
  background: #fff;
  box-sizing: border-box;
}

.full-width-input:focus {
  border: 1px solid #14c871;
  outline: none;
}

.icon-circle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #14c871;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.app-main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px; 
  height: 50px; 
  z-index: 9999; 
}

.columns-container-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.columns-container {
  display: flex;
  width: 100%;
  margin-top: 40px;
}

.steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  flex-shrink: 0; 
  width: 25%;
  max-width: 600px;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.row.header-label {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.row.content {
  font-size: 0.875rem;
  color: #333;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input[type="text"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  width: 300px; 
}

input[type="text"]:focus {
  border-color: #14c871; 
}

.icon {
  width: 20px;
  height: 20px;
}
</style>
