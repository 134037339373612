<template>
  <div class="main-content-container">
    <div class="section-header1">
      <div class="icon-wrapper">
        <i class="fa fa-users"></i> <!-- LLC Members icon -->
      </div>
      <span class="section-title company-details">
        {{ jsonData ? jsonData.llcMembersHeader : 'LLC Members' }}
      </span>
    </div>

    <!-- LLC Members section -->
    <div class="llc-members">
      <div v-for="(member, index) in members" :key="index" class="member-section">
        <div class="section-header2">
          <i class="fa fa-user"></i>
          <span class="section-title">
            {{ jsonData ? jsonData.members : 'Members' }} {{ index + 1 }}
          </span>
        </div>
        <div class="form-group">
          <input
            type="text"
            v-model="member.name"
            :placeholder="jsonData ? jsonData.memberNamePlaceholder : 'Member Name'"
            class="member-input"
          />
          <input
            type="date"
            v-model="member.dob"
            :placeholder="jsonData ? jsonData.dateOfBirthPlaceholder : 'Date of Birth'"
            class="member-input"
          />
          <span v-if="memberErrors[index]?.name" class="error-message">
            {{ jsonData ? jsonData.memberErrors.name : 'Please enter a name.' }}
          </span>
          <span v-if="memberErrors[index]?.dob" class="error-message">
            {{ jsonData ? jsonData.memberErrors.dob : 'Please enter a date of birth.' }}
          </span>
        </div>
      </div>
      <div class="add-member" @click="addMember">
        <i class="fa fa-plus-circle"></i>
        <span>{{ jsonData ? jsonData.addAnotherMember : 'Add Another Member' }}</span>
      </div>
    </div>

    <!-- Next button -->
    <div class="next-button">
      <button class="next-btn" :disabled="!isFormValid" @click="handleNextClick">
        {{ jsonData ? jsonData.nextButton : 'Next' }} 
        <i class="fa fa-arrow-right"></i>
      </button>
    </div>

    <!-- Existing upload view and components -->
    <div class="upload-view">
      <!-- Your existing upload items and components -->
    </div>

    <!-- Modal and other components -->
    <!-- Assuming these are necessary for your application -->
  </div>
</template>


<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export default {
  name: "BaseContent",

  setup() {
    const route = useRoute(); // Use vue-router to get the current route

    const jsonData = ref(null); 
    const store = useStore();
    const members = ref(JSON.parse(localStorage.getItem('members')) || [{ name: '', dob: '' }]);
    const memberErrors = ref([]);

    const validateMembers = () => {
      memberErrors.value = members.value.map(member => ({
        name: !member.name.trim(),
        dob: !member.dob,
      }));
    };

    const isFormValid = computed(() => {
      validateMembers();
      return memberErrors.value.every(errors => !errors.name && !errors.dob);
    });

    const goToStep = (step) => {
      store.commit('setStep', step);
      store.commit('updateCurrentComponent', 'deploy');
    };

    const handleNextClick = () => {
      if (isFormValid.value) {
        // Save to localStorage
        localStorage.setItem('members', JSON.stringify(members.value));
        console.log('Members saved to localStorage:', JSON.stringify(members.value, null, 2)); // Pretty print

        goToStep(4); // Navigate to step 4
      }
    };

    const addMember = () => {
      members.value.push({ name: '', dob: '' });
    };
    const loadLanguageFile = async (lang) => {
          if (lang === 'vn') {
            jsonData.value = await import('../locales/vi.json'); // Load Vietnamese JSON
            console.log('json data',jsonData)
          } else {
            jsonData.value = await import('../locales/en.json'); // Default to English
          }
};
    // Watch for changes to members and update localStorage
    watch(members, (newMembers) => {
      localStorage.setItem('members', JSON.stringify(newMembers));
      console.log('Members updated:', JSON.stringify(newMembers, null, 2)); // Pretty print
    }, { deep: true });
    watch(
            
          () => route.params.lang,
          (newLang) => {
            console.log('new lang',newLang)
            loadLanguageFile(newLang || 'en'); // Load the correct language file, default to 'en'
          },
          { immediate: true } // Run immediately on component mount
      );
    
    return {
      members,
      memberErrors,
      isFormValid,
      handleNextClick,
      addMember,
      jsonData,
    };
  },
};
</script>

<style scoped>
/* Your existing scoped styles */
.main-content-container {
  /* Example styles for layout */
  max-width: 800px;
  margin: 25px;
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Updated font family */
  background-color: #f0f0f0; /* Light background color */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow for depth */
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #007bff; /* Updated text color for the title */
}

.llc-members {
  margin-bottom: 20px;
}

.section-header1 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 25px;
}

.section-header2 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 25px;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-header1 i, .section-header2 i {
  font-size: 24px;
  margin-right: 10px;
  color: #ab3c50;
  padding: 2px;
}

.section-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.member-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.add-member {
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
  color: #007bff;
  font-weight: bold;
}

.add-member i {
  font-size: 24px;
  margin-right: 10px;
}

.next-button {
  text-align: right;
  margin-top: 20px;
}

.next-btn {
  padding: 12px 24px;
  background-color: #ee2b4d; /* Button background color */
  color: #fff; /* Button text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.next-btn:hover {
  background-color: #ff0044; /* Button hover background color */
}

.fa {
  margin-left: 8px;
}

.company-details {
  margin-bottom: 54px;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
</style>
